import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const navContainer: any = css`
    width: 100%;
    background: linear-gradient(#414141 0, #000 55%);
    border-bottom: 3px solid white;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(50, 50, 50, 0.5);
    height: 85px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        width: 100%;
        background: linear-gradient(#414141 0, #000 55%);
        height: auto;
        border-bottom: 3px solid white;
    }
`;

export const companyLogo: any = css`
    position: absolute;
    left: 0;
    top: 10px;
    padding-left: 20px;
    width: 100%;
    max-width: 400px;
    @media (${BREAKPOINTS_DM.bronze_375}) {
        top: 0;
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
        padding-left: 0;
    }
`;

export const menuDesktop: any = css`
    .heightDefiner {
        display: none;
        width: 0;
        .navItemContainer {
            .imageSpacer {
                height: 32px;
            }
        }
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: flex;
        max-width: ${({ theme }) => theme.maxWidth}px;
        width: 100%;
        margin: auto;
        position: relative;
        justify-content: flex-end;
        z-index: 1;
        .heightDefiner {
            display: block;
        }
    }
`;

export const ulMenuDesktop: any = css`
    display: none;
    span {
        width: 100%;
        white-space: normal;
        text-align: center;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: flex;
        position: absolute;
    }
`;

export const menuResponsive: any = css`
    position: fixed;
    z-index: 9999;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: transform ease 0.5s;
    overflow-y: auto;
    display: flex;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: none;
    }
`;

export const ulMenuResponsive: any = css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 80px;
    width: 70%;
    background-color: ${(props) => props.theme.palette.prime};
    box-shadow: -2px -2px 5px rgba(50, 50, 50, 0.5);
    min-height: 100%;
    box-sizing: border-box;
    margin-bottom: auto;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: none;
    }
`;

export const backDrop: any = css`
    width: 30%;
    height: 100%;
    opacity: 0;
`;

export const openSubMenuButton: any = css`
    color: white;
    display: inline-block;
    margin-left: 10px;
    background-image: url("/images/sprite-s82c5aa31b4.png");
    background-repeat: no-repeat;
    background-position: 0 -75px;
    height: 15px;
    transform: translateY(2px);
    width: 15px;
`;

export const active: any = css`
    color: ${(props) => props.theme.palette.prime} !important;
`;

export const activeImage: any = css`
    position: absolute;
    transform: translateY(-111px);
`;

// Mapped navitem Styles

export const navItem: any = css`
    overflow: hidden;
    position: relative;
    width: 95px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .navItemIcon {
        height: 222px;
        position: absolute;
        bottom: -111px;
    }
    .navItemContainer {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .labelContainer {
            position: relative;
            span {
                display: block;
                color: white;
                padding-top: 20px;
                padding-bottom: 10px;
                padding-left: 1px;
                padding-right: 1px;
                hyphens: auto;
                width: 95px;
                font-weight: 300;
            }
            span.labelSpacePlaceholder {
                pointer-events: none;
                opacity: 0;
                display: block;
            }
            span.label {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .imageSpacer {
        height: 65px;
        width: 95px;
        overflow: hidden;
        position: relative;
    }
    &:hover {
        img {
            position: absolute;
            transform: translateY(-111px);
        }
        span {
            color: ${(props) => props.theme.palette.prime};
        }
    }
`;

export const link: any = css``;

export const navItemResponsive: any = css`
    background-color: black;
    width: 99%;
    margin-bottom: 2px;
    border-radius: 10px 0 0 10px;
    border-bottom: 1px solid white;
    position: relative;
    &:hover {
        background-color: #d30032;
    }
`;

export const linkResponsive: any = css`
    padding: 22px;
    display: block;
    color: white;
    font-weight: 700;
    font-size: 18px;
    z-index: 9999999;
`;

export const subNavItemResponsive: any = css`
    background-color: ${(props) => props.theme.palette.bg1};
    width: 100%;
    margin-bottom: 2px;
    border-radius: 10px 0 0 10px;
    color: white;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    &:hover {
        background-color: ${(props) => props.theme.palette.red};
    }
`;
