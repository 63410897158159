import React, { useContext } from "react";
import { FC } from "react";
import { getLineNameFromLine } from "src/common/utils/getLineNameFromLine";
import { Context, IContext } from "../..";
import { getContactCompanyNameShort } from "../ImprintStart";

export const CopyrightNotice: FC<React.PropsWithChildren<any>> = () => {
    const props = useContext<IContext>(Context);
    const copyright = props?.Footer?.copyright;
    const line = props?.CompanyInformationPrivate?.line;

    if (line == "none" || !line) {
        const companyName = props?.CompanyInformationPublic?.companyName;
        if (!companyName) {
            return null;
        }
        return (
            <>
                <strong>
                    &copy; {getContactCompanyNameShort()} {new Date().getFullYear()}{" "}
                </strong>
            </>
        );
    }

    const linenName = getLineNameFromLine(line);

    if (copyright) {
        const currentYear = `${new Date().getFullYear()}`;
        const last20Years = Array.from({ length: 30 }, (v, k) => k).map((i) => `${new Date().getFullYear() - i}`);

        let replacedCopyrightString = `${copyright}`;
        //replace all years with current year
        last20Years.forEach((year) => {
            replacedCopyrightString = replacedCopyrightString.split(year).join(currentYear);
        });

        return replacedCopyrightString || ("" as any);
    }

    return (
        <>
            <strong>
                &copy; {linenName} {new Date().getFullYear()}{" "}
            </strong>
            - Die besten Marken finden Sie bei {linenName}{" "}
        </>
    );
};
