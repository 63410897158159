import { IContext, Context } from "src/common";
import React, { FunctionComponent, useContext } from "react";
import RichText from "../../../BaseComponents/RichText/RichText";
import { cutCorner } from "../../../constants/layout";
import * as styles from "./MainSidebarLeft.style";
import { SNavWrapper } from "./Styles/SNavWrapper";
import { LineLogo } from "src/common/components/LineLogo";
import { richToPlainText } from "../../../../../common/utils/text/plaintext";
import { getLinkFromConnection } from "../../../../../common/utils/getLinkFromConnection";
import { GoogleStars } from "../../../../../common/components/GoogleStars/GoogleStars";
import { css } from "styled-components";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";

import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";
import { SocialIcons } from "./SocialIcons";

export const MainSidebarLeft: FunctionComponent = () => {
    const caisyProps = useContext<IContext>(Context);
    const companyInformationPublic = caisyProps.CompanyInformationPublic;
    const navData = caisyProps.PrimaryNavigation?.items;
    const footer = caisyProps?.Footer;
    const dealer = caisyProps?.dealer ?? {};
    const isGelectra = dealer?.dachseite && caisyProps?.CompanyInformationPrivate?.line === "gelectra";

    const openingHoursContent = getOpeningHoursContent();

    return (
        <div css={styles.wrapper}>
            <SNavWrapper>
                {companyInformationPublic?.logo && (
                    <div css={styles.logoWrapper}>
                        <a href="/">
                            <img css={styles.logo} src={companyInformationPublic?.logo?.[0]?.src} alt="logo" />
                        </a>
                    </div>
                )}
                {dealer?.dachseite && (
                    <div css={styles.gelectraInfo}>
                        <ul>
                            <li>
                                <strong>Haushaltsgeräte</strong>
                            </li>
                            <li>
                                <strong>Elektrogeräte-Austausch</strong>
                            </li>
                            <li>
                                <strong>Küchenrenovierung</strong>
                            </li>
                            <li>
                                <strong>Kundendienst</strong>
                            </li>
                            <li>
                                <strong>Reparatur</strong>
                            </li>
                        </ul>
                        <p>
                            {isGelectra ? "Gelectra" : "Küchen.Geräte.Partner."} ist eine Gemeinschaft inhabergeführter
                            Elektrounternehmen, die durch den gemeinsamen Einkauf bei renommierten Herstellern gute
                            Preise erzielen, die wir direkt an Sie weitergeben.
                        </p>
                    </div>
                )}
                <nav>
                    {navData?.map((navItem, i) => {
                        const link = getLinkFromConnection(navItem?.link);
                        if (!link.valid) {
                            return null;
                        }

                        return (
                            <div key={i} css={styles.navItem_IconRow}>
                                <a href={link.href} title={link.title} target={link.target} rel={link.rel}>
                                    {/* <Img/> hier eher nicht sinnvoll*/}
                                    {navItem?.icon && (
                                        <img
                                            src={
                                                navItem?.icon?.[0]?.src ||
                                                "https://assets.caisy.io/asset/4405302a-1682-4502-bddd-451817d0e8e1/75786649c4dd3b6c9d7a0fdc0084a440/nav4Ex.png"
                                            }
                                            alt="icon"
                                        />
                                    )}
                                    {navItem?.label}
                                </a>
                                {navItem?.subitems?.map((navSubItem, j) => {
                                    const link = getLinkFromConnection([navSubItem]);
                                    if (!link.valid) {
                                        return null;
                                    }
                                    return (
                                        <a
                                            css={styles.subItem}
                                            key={j}
                                            href={link.href}
                                            title={link.title}
                                            target={link.target}
                                            rel={link.rel}
                                        >
                                            {navSubItem?.nameInNavigation}
                                        </a>
                                    );
                                })}
                            </div>
                        );
                    })}
                </nav>
            </SNavWrapper>
            {(companyInformationPublic?.street ||
                companyInformationPublic?.managingDirector ||
                companyInformationPublic?.phone ||
                companyInformationPublic?.postcode) &&
                !dealer?.dachseite && (
                    <div
                        css={`
                            ${cutCorner}${styles.itemWrapper}
                        `}
                    >
                        {companyInformationPublic?.managingDirector && (
                            <p>{companyInformationPublic?.managingDirector}</p>
                        )}
                        {companyInformationPublic?.street && <p>{companyInformationPublic?.street}</p>}
                        {companyInformationPublic?.postcode && companyInformationPublic?.place && (
                            <p>
                                {companyInformationPublic?.postcode} {companyInformationPublic?.place}
                            </p>
                        )}
                        <br />
                        {companyInformationPublic?.phone && <p>Tel: {companyInformationPublic?.phone}</p>}
                        {companyInformationPublic?.fax && <p>Fax: {companyInformationPublic?.fax}</p>}
                    </div>
                )}
            {openingHoursContent && !dealer?.dachseite && richToPlainText(openingHoursContent) != "" && (
                <div
                    css={`
                        ${cutCorner}${styles.itemWrapper}
                    `}
                >
                    <RichText textContent={openingHoursContent} overwriteStyle={styles.richText}></RichText>
                </div>
            )}
            <div
                css={`
                    ${cutCorner}${styles.blackFooterBox}
                `}
            >
                <LineLogo />
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        fontSize: "12px",
                    }}
                >
                    <CopyrightNotice />
                </div>
                <nav>
                    {footer?.Navigationspunkte?.map((navItem, i) => (
                        <a key={i} href={`/${navItem?.slug}`}>
                            {navItem?.nameInNavigation!}
                        </a>
                    ))}
                </nav>

                <GoogleStars
                    overwriteStyle={css`
                        div {
                            margin-top: 1rem;
                        }
                        svg {
                            width: 13px;
                            height: 13px;
                        }
                    `}
                />
            </div>
            {(companyInformationPublic?.instagram ||
                companyInformationPublic?.twitter ||
                companyInformationPublic?.pintrest ||
                companyInformationPublic?.youtube ||
                companyInformationPublic?.facebook) && (
                <div
                    css={`
                        ${cutCorner}${styles.itemWrapper}
                    `}
                >
                    <SocialIcons />
                </div>
            )}
        </div>
    );
};
