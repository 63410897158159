import { useRouter } from "next/router";
import { useState, useContext, useEffect } from "react";
import { Context } from "..";
import { GEDK_LINES } from "../utils/lineShorts";

export function useDealerSearch() {
    const props = useContext(Context);
    const router = useRouter();

    const triggerSearch = async (search: string) => {
        console.log(` props.slug`, props.slug);
        if (props?.PageContact?.slug) {
            await router
                .push(`/${props.PageContact.slug}?search=${encodeURIComponent(search)}#res`, undefined, {
                    shallow: !props.slug || props.slug === props.PageContact.slug,
                })
                .then()
                .catch((e) => console.log(e));
        }
    };

    return { triggerSearch };
}

export function useDealerSearchResult() {
    const router = useRouter();
    const [friendlyCaptchaToken, setFriendlyCaptchaToken] = useState<undefined | string | null>(null);
    const [dealers, setDealers] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const props = useContext(Context);

    const lineShort = props.CompanyInformationPrivate.line;
    const lineId = GEDK_LINES.find((it) => it.short === lineShort)?.id;
    const searchQuery = router.query?.search as string | undefined;

    useEffect(() => {
        const sessionToken = window.sessionStorage.getItem("fcaptcha");
        if (sessionToken && sessionToken != "") {
            setFriendlyCaptchaToken(sessionToken);
        }

        document.addEventListener(
            "fcaptcha",
            function () {
                setFriendlyCaptchaToken(window.sessionStorage.getItem("fcaptcha"));
            },
            false
        );
    }, []);
    useEffect(() => {
        const myWidget = document.getElementsByClassName("frc-captcha")?.[0];
        if (!myWidget) return;
        myWidget.addEventListener("frc:widget.complete", function (event: any) {
            console.log("Widget was completed! Response:", event.detail?.response);
        });

        myWidget.addEventListener("frc:widget.error", function (event: any) {
            console.error("Widget ran into an error:", event.detail?.error);
        });

        myWidget.addEventListener("frc:widget.expired", function (event) {
            console.warn("The widget expired because the user waited too long");
            console.log(` event`, event);
        });
    }, []);

    useEffect(() => {
        (async () => {
            const searchQuery = router.query?.search as string | undefined;

            if (friendlyCaptchaToken && searchQuery) {
                setIsLoading(true);
                const r = await (
                    await fetch(
                        `${
                            process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT ?? "https://api-next.api.gedk.caisy.site"
                        }/api/v1/dealersearch/dealer_in_area\?radius=75000\&line=${lineId}\&searchterm=${encodeURIComponent(
                            searchQuery as any
                        )}${
                            process.env.NODE_ENV == "development" ? "" : ""
                            // process.env.NODE_ENV == "development" ? "" : ""
                        }`, // the fake attrubute is only for development and if fake=true it will return random number of fake result - fake=0 will return empyt - fake=1 will return 1 result
                        {
                            headers: {
                                "x-captcha-token": friendlyCaptchaToken,
                            },
                        }
                    )
                ).json();
                if (r?.success === false) {
                    if (r?.error === "Invalid token") {
                        setFriendlyCaptchaToken(null);
                        await window.sessionStorage.removeItem("fcaptcha");
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                        return;
                    }
                    console.log(` r`, r);
                    return;
                }

                setDealers(
                    r
                        ?.map((it) => ({
                            ...it,
                            domain:
                                "https://" + `${(it.domain ?? `${it.slug}.gedk.caisy.site`).replace("https://", "")}`,
                        }))
                        .sort((a, b) => a?.distance_from_location - b?.distance_from_location)
                );
                setIsLoading(false);
            }
        })();
    }, [searchQuery, friendlyCaptchaToken]);

    return { dealers, friendlyCaptchaToken, isLoading };
}
