import React, { FC } from "react";
import { IPhotoGallery } from "src/common/childTypes";

import { BREAKPOINTS } from "src/common/constants/mediaquerys";
import { useWindowDimensions } from "src/common/utils/useWindowDimensions";
import { FancyImageWithModal } from "src/common/BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { SMosaicPhotoGallery } from "./styles/SMosaicPhotoGallery";
import { SMosaicPhotoGalleryItemWrapper } from "./styles/SMosaicPhotoGalleryItemWrapper";
import { IGenAsset } from "src/common/types_dealer_gen";
import { getAnimation } from "src/common/constants/animations";
import FullScreenSliderWithFancyImage from "../../FullScreenSliderWithFancyImage/FullScreenSliderWithFancyImage";

const MosaicPhotoGallery: FC<{ item: IPhotoGallery; hasAnimation?: boolean }> = ({ item, hasAnimation }) => {
    if (!item?.elements) return null;
    // console.log(` item`, item);

    const { windowWidth } = useWindowDimensions();
    const isBiggerThanBronze = windowWidth > BREAKPOINTS.SILVER - 1;
    const oneRowMode = item?.maxCountPerRow == 1;
    const itemsPerRow = oneRowMode ? 2 : isBiggerThanBronze ? 4 : 2;

    // console.log(` itemsPerRow`, itemsPerRow);
    const animation = getAnimation(item.visualization);

    const getCol = (index) => {
        switch (itemsPerRow) {
            case 4:
                switch (index % itemsPerRow) {
                    case 0:
                        return 1;
                    case 1:
                        return 3;
                    case 2:
                        return 0;
                    case 3:
                        return 2;
                    default:
                        return;
                }
            case 2:
                return (index + 1) % itemsPerRow;
            default:
                return index % itemsPerRow;
        }
    };

    const chunks: Array<IGenAsset[]> = [];
    for (let x = 0; x < item.elements.length; x++) {
        const colIndex = getCol(x);

        if (!chunks[colIndex]) {
            chunks[colIndex] = [];
        }
        chunks[colIndex].push(item.elements[x]);
    }

    // console.log(` chunks`, chunks);

    return (
        <SMosaicPhotoGallery data-gallery>
            {chunks?.map(
                (chunk, parentIndex) =>
                    (
                        <SMosaicPhotoGalleryItemWrapper
                            oneRowMode={oneRowMode}
                            key={`gallery-image-${parentIndex}`}
                            column={parentIndex}
                        >
                            {chunk?.map(
                                (image, childIndex) =>
                                    (
                                        <div
                                            key={`gallery-image-item-${childIndex}`}
                                            data-gallery-item
                                            {...(itemsPerRow != 2 && {
                                                "data-aos": hasAnimation ? animation : false,
                                            })}
                                            className={
                                                image?.description && image?.description !== "" ? "has-caption" : ""
                                            }
                                        >
                                            <div
                                                data-image
                                                {...(itemsPerRow == 2 && {
                                                    "data-aos": hasAnimation ? animation : false,
                                                })}
                                            >
                                                {image &&
                                                    (item.modal === "slider" ? (
                                                        <FullScreenSliderWithFancyImage
                                                            elements={item.elements}
                                                            img={image}
                                                            responsiveAspectRatio={{ bronze: 1 }}
                                                            responsiveFactor={{ bronze: 0.5, silver: 0.25 }}
                                                            responsiveMaxWidth={{ platinum: 1366 / 4 }}
                                                        />
                                                    ) : (
                                                        <FancyImageWithModal
                                                            img={image}
                                                            responsiveAspectRatio={{ bronze: 1 }}
                                                            responsiveFactor={{ bronze: 0.5, silver: 0.25 }}
                                                            responsiveMaxWidth={{ platinum: 1366 / 4 }}
                                                        />
                                                    ))}
                                            </div>
                                            <div
                                                {...(itemsPerRow == 2 && {
                                                    "data-aos": hasAnimation ? animation : false,
                                                })}
                                                data-caption
                                            >
                                                {image?.description && <p>{image.description}</p>}
                                            </div>
                                        </div>
                                    ) ?? null
                            )}
                        </SMosaicPhotoGalleryItemWrapper>
                    ) ?? null
            )}
        </SMosaicPhotoGallery>
    );
};

export default MosaicPhotoGallery;
