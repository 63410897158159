import React, { useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context, IAsset } from "src/common";

import { Wrapper, Container } from "./NewSlider.style";
import NewSlide from "./slide/NewSlide";

const NewSlider: any = (props) => {
    const p = useContext(Context);
    const news = props.newCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
    const newsSlug = p.NewsSlug || p.PageNews?.slug;
    if ((!news || news.length <= 0) && !props.articles) {
        return null;
    }
    const newsCustom = props.newCustom;

    const newsToRender =
        news?.filter((item) => {
            if (!item) {
                return false;
            }
            if (!item?.startDate && !item?.endDate) {
                return true;
            } else if (!item?.startDate && item?.endDate) {
                return new Date() <= new Date(item?.endDate);
            } else if (item?.startDate && !item?.endDate) {
                return new Date() >= new Date(item?.startDate);
            } else {
                return new Date() >= new Date(item?.startDate) && new Date() <= new Date(item?.endDate);
            }
        }) || props.articles;

    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: props.articles ? 1 : 2,
        slidesToScroll: props.articles ? 1 : 2,
        adaptiveHeight: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2,
                },
            },
            {
                // this will start from 768px and up
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                },
            },
        ],
    };

    return (
        <Container className={`news-slider${newsCustom ? " custom" : " root"}`}>
            <Wrapper fullHeight={props && true}>
                <Slider {...sliderSettings}>
                    {newsToRender.map((NewsItem, i) => (
                        <NewSlide
                            key={i + NewsItem?.id}
                            index={i}
                            totalCount={newsToRender.length}
                            img={NewsItem?.extras as IAsset[]}
                            imgAlt={(NewsItem?.extras as IAsset[])?.[0]?.description}
                            title={NewsItem?.headline}
                            text={NewsItem?.text}
                            newpage={props && true}
                            link={newsSlug && `/${newsSlug}/${NewsItem?.id}`}
                        />
                    ))}
                </Slider>
            </Wrapper>
        </Container>
    );
};

export default NewSlider;
