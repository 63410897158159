import React, { FunctionComponent, useContext } from "react";
import { IContext, Context, getPage } from "src/common";
import { LineLogo } from "../../../../../common/components/LineLogo";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import UniversalComponentsRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import { cutCorner } from "../../../constants/layout";
import Micro from "../Micro";
import * as styles from "./MainAreaWrapper.style";
import { twoColumnsWrapper } from "./MainAreaWrapper.style";
import { NewSlider } from "./NewSlider/NewSlider";
import { SidebarRight } from "./sidebarRight/SidebarRight";
import { TopBar } from "./topBar/TopBar";
import { FounderForm } from "../FounderForm/FounderForm";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";
import { SocialIcons } from "../mainSidebarLeft/SocialIcons";

interface FunctionInterface {
    children?: React.ReactNode;
    topChildren?: React.ReactNode;
    skipFirstText?: boolean;
    useOneTextForForm?: boolean;
    micro?: boolean;
}

export const MainAreaWrapper: FunctionComponent<FunctionInterface> = (props) => {
    const caisyProps = useContext<IContext>(Context);
    const footer = caisyProps?.Footer;
    const companyInformationPublic = caisyProps?.CompanyInformationPublic;
    let elements = getPage(caisyProps)?.elements;

    if (props.skipFirstText) {
        elements = elements.slice(1);
    }
    if (props.useOneTextForForm) {
        if (elements.length > 0) {
            // cut latest element
            elements = elements.slice(0, -1);
        }
    }

    // console.log(` p.slug`, caisyProps.slug);
    // console.log(` getPage(caisyProps)`, getPage(caisyProps));
    // console.log(` elements`, elements);

    const connectedArticles = caisyProps.PageBrand?.connectedArticles || caisyProps.PageBrand?.connectedArticles;

    const isFounderPage =
        caisyProps?.dealer?.dachseite && ["gruender", "grunder", "Gründer"].includes(caisyProps?.Page?.slug);

    return (
        <div css={styles.wrapper}>
            <TopBar />
            <div css={twoColumnsWrapper}>
                {props?.micro && <Micro />}
                <div css={styles.pageContent}>
                    {!(caisyProps?.PageImprint?.slug && caisyProps?.PageImprint?.slug === caisyProps.slug) && (
                        <div>
                            <UniversalComponentsRenderer children={props.topChildren} items={elements} />
                        </div>
                    )}
                    {caisyProps?.PageLanding?.News && (
                        <div
                            css={`
                                ${cutCorner}${styles.sliderWrapper}
                            `}
                        >
                            <div>
                                <NewSlider newsSlug={caisyProps.NewsSlug} news={caisyProps?.PageLanding?.News} />
                            </div>
                        </div>
                    )}

                    {caisyProps?.PageLanding?.NewsCustom && caisyProps?.PageLanding?.NewsCustom?.length > 0 && (
                        <div
                            css={`
                                ${cutCorner}${styles.sliderWrapper}
                            `}
                        >
                            <div>
                                <NewSlider newsSlug={caisyProps.NewsSlug} news={caisyProps?.PageLanding?.NewsCustom} />
                            </div>
                        </div>
                    )}
                    {connectedArticles?.map((article, i) => {
                        const isDisplayedWithBorder = article?.extras?.length < 3;
                        return (
                            <div
                                key={i}
                                css={`
                                    ${styles.connectedArticle}${isDisplayedWithBorder
                                        ? styles.connectedArticleWithBorder
                                        : ""}${isDisplayedWithBorder ? cutCorner : ""}
                                `}
                            >
                                <div css={styles.connectedArticleRichtext}>
                                    {article?.headline && article?.extras?.length > 2 && (
                                        <h2>{article?.headline}</h2>
                                        // <h2 className={ !!article?.extras?.find((extra) => extra?.__typename === "Asset" && extra?.src) ? "withIcon" : ""}>{article.headline.replace(/\./g, '.<wbr>')}</h2>
                                    )}
                                    {article?.extras?.map((extra, j) => {
                                        if (extra?.__typename === "Asset") {
                                            return (
                                                <div
                                                    key={j}
                                                    css={
                                                        isDisplayedWithBorder
                                                            ? styles.connectedArticleImg
                                                            : `${styles.connectedArticleImg}${styles.connectedArticleImgThreeInARow}`
                                                    }
                                                >
                                                    <Img quality={600} key={j} src={extra?.src} alt="bild" />
                                                </div>
                                            );
                                        }
                                    })}
                                    {article?.extras?.length <= 2 && <h3>{article?.headline}</h3>}
                                    {article?.mainText && <RichText textContent={article?.mainText} fragment />}
                                </div>
                            </div>
                        );
                    })}
                    {caisyProps?.Page?.footnote && (
                        <RichText textContent={caisyProps.Page.footnote} overwriteStyle={styles.footnote}></RichText>
                    )}
                    {props.children}
                    {isFounderPage && <FounderForm />}
                </div>
                <SidebarRight />
            </div>
            <div
                css={`
                    ${cutCorner}${styles.blackFooterBox2}
                `}
            >
                <LineLogo />
                <div style={{ display: "flex", flexWrap: "wrap", paddingTop: "8px", fontSize: "12px" }}>
                    <CopyrightNotice />
                </div>
                <nav>
                    {footer?.Navigationspunkte?.map((navItem, i) => (
                        <a key={i} href={`/${navItem?.slug}`}>
                            {navItem?.nameInNavigation!}
                        </a>
                    ))}
                </nav>
                {(companyInformationPublic?.instagram ||
                    companyInformationPublic?.twitter ||
                    companyInformationPublic?.pintrest ||
                    companyInformationPublic?.youtube ||
                    companyInformationPublic?.facebook) && (
                    <div
                        css={`
                            padding: 12px 0;
                        `}
                    >
                        <SocialIcons />
                    </div>
                )}
            </div>
        </div>
    );
};
