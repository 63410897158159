import { Context } from "src/common";
import React, { Fragment, FunctionComponent, useContext } from "react";

import { flexItem100 } from "../../../constants/layout";
import * as styles from "./Footer.style";
import { addHttpsToLinks } from "../../../../../common/utils/addHttpsToLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";
import { css } from "styled-components";

export const FooterT5: FunctionComponent = () => {
    const props = useContext(Context);
    const navItems = props?.Footer?.Navigationspunkte;
    const companyInformation = props?.CompanyInformationPublic;

    return (
        <div
            id="FooterT5"
            css={`
                ${flexItem100}${styles.wrapper}
            `}
        >
            <p css={styles.links}>
                <CopyrightNotice />
                &nbsp;{"|"}&nbsp;
                {navItems?.map((navItem, i) => (
                    <Fragment key={i}>
                        {(i !== 0 ||
                            (i === 0 && props?.Footer?.copyright && props?.Footer?.copyright?.trim() !== "")) && (
                            <span>|</span>
                        )}
                        <a href={`/${navItem?.slug}`} key={i}>
                            {navItem?.nameInNavigation}
                        </a>
                    </Fragment>
                ))}
            </p>
            <div>
                <div css={styles.shareOnSocialMediaWrapper}>
                    {companyInformation?.facebook && (
                        <a
                            className="facebook"
                            href={addHttpsToLinks(companyInformation?.facebook)}
                            title={"Bei Facebook teilen"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                            </svg>
                        </a>
                    )}
                    {companyInformation?.twitter && (
                        <a
                            className="twitter"
                            href={addHttpsToLinks(companyInformation?.twitter)}
                            title={"Bei Twitter teilen"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            css={css`
                                width: 24px;
                                height: 24px;
                                margin-top: 2px;
                            `}
                        >
                            <FontAwesomeIcon color="white" icon={faXTwitter} />
                        </a>
                    )}
                    {companyInformation?.pintrest && (
                        <a
                            className="pintrest"
                            href={addHttpsToLinks(companyInformation?.pintrest)}
                            title={"Bei Pinterest teilen"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </a>
                    )}
                    {companyInformation?.youtube && (
                        <a
                            className="youtube"
                            href={addHttpsToLinks(companyInformation?.youtube)}
                            title={"Youtube"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="16"
                                fill="none"
                                viewBox="0 0 500 346"
                            >
                                <path
                                    fill="#fff"
                                    d="M154 1c-44.8 1.6-77.5 4.3-93.5 7.6-13.4 2.8-30.8 13-39 22.9C9.7 46 5.6 61.1 1.8 104 0 125 0 221.8 1.8 242c2.8 31.4 6.7 51.7 11.9 61.1 9.3 17.2 28.4 30.5 49.8 34.8 12.1 2.4 35.1 4.6 66.5 6.3 32.2 1.8 203.4 1.8 236.5 0 74.5-3.9 88.5-7.4 107.6-26.2 13.7-13.6 18.3-27.2 22.3-66.3 3.9-36.8 4.6-102.2 1.6-138.2-4.4-53.3-9.7-72.4-24.4-86.9-7.3-7.3-16.3-12.8-26.1-16-10.6-3.4-39.9-6.4-85.5-8.7C334.8.6 185.2-.1 154 1zm111.8 134c33.6 20.6 61.1 37.7 61.1 38 0 .4-113.7 70.6-123.5 76.3-1.2.7-1.4-11.1-1.4-76.3s.2-77 1.4-76.3c.7.4 28.8 17.7 62.4 38.3z"
                                ></path>
                            </svg>
                        </a>
                    )}
                    {companyInformation?.instagram && (
                        <a
                            className="instagram"
                            href={addHttpsToLinks(companyInformation?.instagram)}
                            title={"Instagram"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 1200 1200"
                            >
                                <path
                                    fill="#fff"
                                    d="M303.5 10c-43.1.7-54.1 1.5-79.5 6.1-26.5 4.7-47.3 11.4-71.5 23.3-27.5 13.5-51.8 31.2-70.4 51.2C37.4 138.5 15.3 194.2 11 269.3c-1.6 29-2.1 183.8-1.3 389.5l.8 192.3 9.5-7.6c40-32.1 89.3-60.3 134.8-77l10.3-3.8-.8-17.1c-1.3-27.9-.9-271.1.5-301.6 2.6-56.1 5.4-77.7 13.8-106.6 13.6-47.2 37.6-84.6 73.4-114.6 38.1-31.9 86.8-50.1 148-55.3 38.1-3.2 73.7-3.9 201-3.9 172.3 0 207.5 1.7 249.5 11.9 44.2 10.7 78.6 28.8 108.4 57.1 33.2 31.6 53.9 68.4 65.6 116.9 10.2 42.3 11.9 76.9 11.9 250.5 0 124.6-.6 160.2-3.5 196-5.6 70.4-26.7 122.6-66.1 163.5-41.9 43.6-92.6 66-164.7 73-8.5.8-17.8 1.5-20.7 1.5H776l2.6 5.7c20.5 45.1 36.5 97.3 44.9 146.7l.6 3.8 50.7-.5c68.8-.7 92.5-3 129-12.7 61.8-16.5 115.3-57.3 148.4-113.3 20.3-34.2 30.8-68.3 36.4-117.7 1.7-14.5 1.8-36.6 1.8-346.5 0-310.1-.1-332-1.8-346.5-6.1-53.5-18.7-91.3-43.3-128.9-12.7-19.5-36.7-45-56.3-59.8-42.3-31.9-90.9-48.5-156.5-53.4-17.2-1.3-558.7-2.1-629-.9z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M491 243.1c-81.8 1.1-109.4 3.8-139 13.6-23.4 7.7-40.2 17.7-56.8 33.7-13.2 12.7-21.8 24.2-28.7 38.1-13.4 27.2-18.9 52.8-21.7 100.5-1.4 23.3-1.9 311.2-.6 312.5.4.4 3.5.2 7-.4 16.8-2.9 35.3-4.3 62.8-4.8 29.8-.5 44.9.2 70 3.3 11.1 1.4 40.4 6.6 45.3 8 1.2.4-.4-2.2-4.1-6.8-20.1-24.5-37.1-59.8-44.1-91.4-5.2-23.2-6.6-54.8-3.6-78 6.5-49.6 28.8-94.9 64-129.9 33-33 73.9-54.2 120.5-62.6 16.7-3 48-3.7 65.8-1.5 67.3 8.6 126.3 46.3 162.7 104.1 12 19.1 23.6 47.2 28.4 69.1 6.8 30.8 6.8 68.1 0 98.8-7.3 33.3-24.9 68.5-47.7 95.6-38.7 46.2-97.7 75.8-157.3 78.8l-12.7.7 14.2 10.7c41.5 31.4 80.6 71.6 110.8 114.1l4.8 6.7h6.4c13.7 0 52.7-2.1 64.6-3.6 50.4-5.9 85.5-22.4 111.7-52.2 18.1-20.8 29.4-45 35.6-76.7 6.6-33.4 7.2-51.9 7.2-224.5 0-152.1-.5-174.9-4.1-204-4-32.4-13.4-60-27.6-81.5-7.1-10.8-27.9-31.5-38.9-38.8-14-9.2-31.8-16.8-50.4-21.6-21.6-5.4-41.7-7.5-86.5-9.1-36.3-1.2-189.6-1.8-258-.9zm352.3 72.4c7.3 1 20 7.8 26.7 14.5 7.6 7.6 13.6 19.5 15 29.7 3.8 27.5-14.3 53.1-41.5 58.8-8.8 1.8-12.2 1.8-21 0-36.7-7.7-53.8-49.6-33.1-80.9 8.6-12.8 23.3-21.3 40.1-22.9 3.2-.3 6-.1 13.8.8z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M584 455.6c-8.1 1-23.8 4.8-31.9 7.6-48.1 17-83.3 57.3-94.3 108.1-3.1 14.7-3.1 42.7 0 57.4 12.4 57.7 55.8 101.1 113.5 113.5 14.7 3.1 42.7 3.1 57.4 0 58.4-12.6 101.2-55.8 113.9-115.2 2.6-12 2.6-42 0-54-8.8-40.9-30.5-73.1-64.1-94.9-14.3-9.4-30.1-15.8-49-20.1-8.8-2-36.7-3.5-45.5-2.4z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M895.9 1189.2c43.1-.7 54.1-1.5 79.5-6.1 26.5-4.7 47.3-11.4 71.5-23.3 27.5-13.5 51.8-31.2 70.4-51.2 44.7-47.9 66.8-103.6 71.1-178.7 1.6-29 2.1-183.8 1.3-389.5l-.8-192.3-9.5 7.6c-40 32.1-89.3 60.3-134.8 77l-10.3 3.8.8 17.1c1.3 27.9.9 271.1-.5 301.6-2.6 56.1-5.4 77.7-13.8 106.6-13.6 47.2-37.6 84.6-73.4 114.6-38.1 31.9-86.8 50.1-148 55.3-38.1 3.2-73.7 3.9-201 3.9-172.3 0-207.5-1.7-249.5-11.9-44.2-10.7-78.6-28.8-108.4-57.1-33.2-31.6-53.9-68.4-65.6-116.9-10.2-42.3-11.9-76.9-11.9-250.5 0-124.6.6-160.2 3.5-196 5.6-70.4 26.7-122.6 66.1-163.5 41.9-43.6 92.6-66 164.7-73 8.5-.8 17.8-1.5 20.7-1.5h5.4l-2.6-5.7c-20.5-45.1-36.5-97.3-44.9-146.7l-.6-3.8-50.7.5c-68.8.7-92.5 3-129 12.7C133.8 38.7 80.3 79.5 47.2 135.5c-20.3 34.2-30.8 68.3-36.4 117.7C9.1 267.7 9 289.8 9 599.7c0 310.1.1 332 1.8 346.5 6.1 53.5 18.7 91.3 43.3 128.9 12.7 19.5 36.7 45 56.3 59.8 42.3 31.9 90.9 48.5 156.5 53.4 17.2 1.3 558.7 2.1 629 .9z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M708.4 956.1c81.8-1.1 109.4-3.8 139-13.6 23.4-7.7 40.2-17.7 56.8-33.7 13.2-12.7 21.8-24.2 28.7-38.1 13.4-27.2 18.9-52.8 21.7-100.5 1.4-23.3 1.9-311.2.6-312.5-.4-.4-3.5-.2-7 .4-16.8 2.9-35.3 4.3-62.8 4.8-29.8.5-44.9-.2-70-3.3-11.1-1.4-40.4-6.6-45.3-8-1.2-.4.4 2.2 4.1 6.8 20.1 24.5 37.1 59.8 44.1 91.4 5.2 23.2 6.6 54.8 3.6 78-6.5 49.6-28.8 94.9-64 129.9-33 33-73.9 54.2-120.5 62.6-16.7 3-48 3.7-65.8 1.5-67.3-8.6-126.3-46.3-162.7-104.1-12-19.1-23.6-47.2-28.4-69.1-6.8-30.8-6.8-68.1 0-98.8 7.3-33.3 24.9-68.5 47.7-95.6 38.7-46.2 97.7-75.8 157.3-78.8l12.7-.7L584 364c-41.5-31.4-80.6-71.6-110.8-114.1l-4.8-6.7H462c-13.7 0-52.7 2.1-64.6 3.6-50.4 5.9-85.5 22.4-111.7 52.2-18.1 20.8-29.4 45-35.6 76.7-6.6 33.4-7.2 51.9-7.2 224.5 0 152.1.5 174.9 4.1 204 4 32.4 13.4 60 27.6 81.5 7.1 10.8 27.9 31.5 38.9 38.8 14 9.2 31.8 16.8 50.4 21.6 21.6 5.4 41.7 7.5 86.5 9.1 36.3 1.2 189.6 1.8 258 .9zm-352.3-72.4c-7.3-1-20-7.8-26.7-14.5-7.6-7.6-13.6-19.5-15-29.7-3.8-27.5 14.3-53.1 41.5-58.8 8.8-1.8 12.2-1.8 21 0 36.7 7.7 53.8 49.6 33.1 80.9-8.6 12.8-23.3 21.3-40.1 22.9-3.2.3-6 .1-13.8-.8z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M615.4 743.6c8.1-1 23.8-4.8 31.9-7.6 48.1-17 83.3-57.3 94.3-108.1 3.1-14.7 3.1-42.7 0-57.4-12.4-57.7-55.8-101.1-113.5-113.5-14.7-3.1-42.7-3.1-57.4 0-58.4 12.6-101.2 55.8-113.9 115.2-2.6 12-2.6 42 0 54 8.8 40.9 30.5 73.1 64.1 94.9 14.3 9.4 30.1 15.8 49 20.1 8.8 2 36.7 3.5 45.5 2.4z"
                                ></path>
                                <path fill="#fff" d="M298 768H435V901H298z"></path>
                            </svg>
                        </a>
                    )}
                    {companyInformation?.email && (
                        <a className="mail" href={`mailto:${companyInformation?.email}`} title={"Per E-Mail teilen"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
                            </svg>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
